import React from "react"
import { Link } from 'gatsby'

export default function Entrepreneurs() {
  return (

    <section id="clients" className="section-bg">
      <div className="container" >

      <br></br>
        <h3 className="section-header" style={{ fontSize: "20px", textAlign: "left", }}>We work with all kinds of business, ranging from startups, growing enterprises to multinational companies. Our goal is help you advance regardless of the business size and industry.</h3>

      </div>
      <br></br>
 
      <section id="about">
        <div className="container" data-aos="fade-up">

          <section id="our-products">
            <div className="row row-eq-height justify-content-center">
              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="200">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Enterprise/download_copy_2-removebg-preview.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Enterprise
                    </h5>
                  </div>
                  <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      For businesses seeking expansion we will be a trusted partner with technical expertise, support, implementation capabilities, industry experience, and a global network.
                    </p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="300">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Growth/Growth_1_copy_3-removebg-preview.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Growth
                    </h5>
                  </div>
                  <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      If you are a growing business, we will help you streamline operations and attract and retain the right talent to finance your growth.
                    </p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="100">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Startup/startup-icon-18 copy.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Start-Up
                    </h5>
                  </div>
                  <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      If you have a minimally viable product, early customer interest and ready to, or have already raised money for product trials, we are here for you.
                    </p>

                  </div>
                </div>
              </div>
<br></br>
              <div className="row row-eq-height justify-content-center">

                <div className="col-lg-4 mb-4">

                  <center>

                    <Link to="/contact" className="page-button">
                      <b>Connect With Us</b>
                    </Link>
                  </center>
                </div>
              </div>



            </div>
          </section>
        </div>
        <br></br>
        <br></br>
      </section>

      <h2 style={{ textAlign: 'center', marginBottom: "-20px",  }}><b> Start-up Programs </b></h2>
      {/* <img src="https://xccelerata.com//wp-admin/images/programs8.jpg" className="img-startup" alt="" /> */}
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/program-XFA.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">XFA – Xccelerata Funding Acquisition Program </h1>
          <hr />
          <p className="founder-p-text">If you have a minimally viable product, and have early customer interest and validation, we can help you find funding for your business through our XFA Program and our extensive network of angel investors, government programs, institutions, corporations, and venture capitalists. </p>
        </div>
      </div>

      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/programs1.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">XTI - Xccelerata Tech Investment Program  </h1>
          <hr />
          <p className="founder-p-text">We know that technology is a core asset in a company’s ability to scale and grow rapidly. Our XTI Program invests in companies by developing technology to facilitate product development and the successful dissemination of the product into the marketplace. </p>
        </div>
      </div>


      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/programs2.png" alt="" />

        </div>
        <div className="team-flex-item2" >
          <h1 className="team-h1">XIP – Xccelerata Intellectual Property Program  </h1>
          <hr />
          <p className="founder-p-text">We know the importance of the need to secure your intellectual property. We have developed a specific, cost effective program and ecosystem of trusted partners, around the process of acquiring patents, trademarks, and other legal entities needed to protect your intellectual property.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
        </div>
      </div>



      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/programs3.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">XGM - Xccelerata Growth Mentorship Program  </h1>
          <hr />
          <p className="founder-p-text">Our XGM Program is designed to help and guide you in the development, implementation, or expansion of your idea or business. We provide an extensive network of industry and domain experts with the knowledge, application experience, and leadership to ensure your company’s success. </p>
        </div>
      </div>

      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/programs4.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">XPE - Xccelerata Partner Ecosystem Program  </h1>
          <hr />
          <p className="founder-p-text">Our XPN Program is a network of talented and vetted individuals, companies and institutions that can support you with knowledge, expertise, products and services to complement your own in order to successfully launch, scale and grow your idea or business.  </p>
        </div>
      </div>
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/programs5.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">XTA - Xccelerata Talent Acquisition Program  </h1>
          <hr />
          <p className="founder-p-text">There is nothing more important to a technology-based company than talented and motivated people to drive the business forward. Our XTA Program, through a discerning group of talent acquisition specialists, finds and attracts the right people to help to grow your fledgling or growing business into a successful global enterprise. </p>
        </div>
      </div>
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/programs6.png" alt="" />
        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">XCA - Xccelerata Customer Acquisition Program  </h1>
          <hr />
          <p className="founder-p-text">We understand that customer acquisition is a fundamental of business development, and for continued growth. Central to this are effective marketing strategies, tools, and tactics, as well as continued product innovations and refinements. Our XCA Program is designed to help you develop and execute effective marketing strategies, and develop product innovations to drive continued customer acquisition, sales and profits.</p>
        </div>

      </div>
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon" src="/assets/img/programs/programs7.png" alt="" />
        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">XOS - Xccelerata Ongoing Support Program </h1>
          <hr />
          <p className="founder-p-text">We know that may start-up companies lack the resources for technology support. We offer a specific program to support your company today and eventually transition your company to a self-supporting capability. </p>
        </div>

      </div>
     
      <img src="https://xccelerata.com/wp-admin/images/services6.jpg" className="img-startup" alt="" />


      {/* <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon1" src="/assets/img/Strategic consultation/png-clipar_copy-removebg-preview.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">Strategic Consultation & Technology Assessment    </h1>
          <hr />

          <p className="founder-p-text">Investigate and evaluate the existing operations & technology based on your goals.  </p>

        </div>
      </div>
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon1" src="/assets/img/Technology plan/downloadx-removebg-preview.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">Technology Plan   </h1>
          <hr />

          <p className="founder-p-text">Develop new technology, improve existing one or integrate both to implement your business and operational goals.</p>

        </div>
      </div>
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon1" src="/assets/img/Technology development/127-1272123_webd-application-development-icon-web-application-icon-png copy.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">Technology Development  </h1>
          <hr />

          <p className="founder-p-text">Create practical, cost effective, custom technology to improve and optimize operations.  </p>

        </div>
      </div>
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon1" src="/assets/img/Technology implementation and training/abc-removebg-preview.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">Technology Integration, Implementation, and Training   </h1>
          <hr />

          <p className="founder-p-text">Ensure a smooth transition by running new applications parallel to legacy technology and training the staff for adoption.   </p>

        </div>

      </div>
      <div className="team-flex-container">
        <div className="team-flex-item1">
          <img className="img-icon1" src="/assets/img/Monitoring and support/monitor-support-icon copy.png" alt="" />

        </div>
        <div className="team-flex-item2">
          <h1 className="team-h1">Monitoring & Ongoing Support </h1>
          <hr />

          <p className="founder-p-text">Monitor the efficiency of new technology and provide monthly reports on the parameters and metrics of your choice.  </p>

        </div>

      </div>
      <div className="row row-eq-height justify-content-center">
        <div className="col-lg-4 mb-4">
          <center>
            <Link to="https://xconnect.xccelerata.com/login" target="_blank" className="btn lg-page-button">
              Join Us Now
            </Link>
          </center>
        </div>
      </div> */}
      <section id="about">
        <div className="container" data-aos="fade-up">
        <h2 style={{ textAlign: 'center', marginTop: "40px", }} className="section-header"><b> Enterprise Services </b></h2>
          <br />
          {/* <header className="section-header">
          <h3>Enterprise Services</h3>
        </header> */}
          <section id="our-products">
            <div className="row row-eq-height justify-content-center">
              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="200">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Strategic consultation/png-clipar_copy-removebg-preview.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Strategic Consultation & Technology Assessment
                    </h5>
                  </div>
                  <div id="datalogger-text">
                    <p className="banner-text">
                      Investigate and evaluate the existing operations & technology based on your goals.
                    </p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="300">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Technology plan/downloadx-removebg-preview.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Technology Plan
                    </h5>
                  </div>
                  <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      Develop new technology, improve existing one or integrate both to implement your business and operational goals.
                    </p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="100">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Technology development/127-1272123_webd-application-development-icon-web-application-icon-png copy.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Technology Development
                    </h5>
                  </div>
                  <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      Create practical, cost effective, custom technology to improve and optimize operations.
                    </p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="100">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Technology implementation and training/abc-removebg-preview.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Technology Integration, Implementation, and Training
                    </h5>
                  </div>

                  <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      Ensure a smooth transition by running new applications parallel to legacy technology and training the staff for adoption.
                    </p>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="100">
                  <div className="card-body">
                    <div className="card-text">
                      <img
                        src="/assets/img/Monitoring and support/monitor-support-icon copy.png"
                        className="product" style={{ height: "100px", width: "100px", position: "relative", }}
                      />
                    </div>
                    <br></br>
                    <h5 className="card-title">
                      Monitoring & Ongoing Support
                    </h5>

                  </div>
                  <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      Monitor the efficiency of new technology and provide monthly reports on the parameters and metrics of your choice.
                    </p>

                  </div>
                </div>
              </div>


            </div>



          </section>
        </div>
      </section>
    
       <br></br>
      <div className="row row-eq-height justify-content-center">

        <div className="col-lg-4 mb-4">

          <center>

            <Link to="/contact" target="_blank" className="page-button" style={{ width: "300px", }}>
              <b>Let's Meet & Discuss Growth </b>
            </Link>
          </center>
        </div>
      </div>

    </section>

  )
}
